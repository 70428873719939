import React, { useEffect } from 'react'
import BlobList from '../components/BlobList'
import { Link } from '@mui/material'
import './BlobPage.css';

const BlobPage = () => {
    const blob_title = process.env.REACT_APP_BLOB_TITLE
    const blob_storage_account = process.env.REACT_APP_BLOB_STORAGE_ACCOUNT
    const blob_container = process.env.REACT_APP_BLOB_CONTAINER
    const blob_url_base = `https://${blob_storage_account}.blob.core.windows.net/${blob_container}/`
    const blob_page_size = parseInt(process.env.REACT_APP_BLOB_PAGE_SIZE)

    const install_guide_url = process.env.REACT_APP_INSTALL_GUIDE_URL
    const install_guide_title = process.env.REACT_APP_INSTALL_GUIDE_TITLE

    useEffect(() => {
        document.title = process.env.REACT_APP_BLOB_TITLE
    }, [])

    return (
        <div className='Blob'>
            <div className='Blob-header'>
                <h1>{blob_title}</h1>
            </div>
            <div className='Blob-list'>
                <Link sx={{textAlign: 'left'}} href={install_guide_url} rel="noopener" target="_blank">{install_guide_title}</Link>
                <p></p>
                <BlobList urlBase={blob_url_base} pageSize={blob_page_size}  />
            </div>
        </div>
    )
}

export default BlobPage