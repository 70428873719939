import React, {useState, useEffect} from 'react'
import { ContainerClient } from '@azure/storage-blob'
import { Box, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const BlobList = (prop) => {
    const { urlBase, pageSize } = prop
    const [items, setItems] = useState([])

    useEffect(() => {
        const getBlobs = async (blob_url_base) => {
            const blob_token = process.env.REACT_APP_BLOB_TOKEN
            const blob_container_url = `${blob_url_base}?${blob_token}`
            const blob_container_client = new ContainerClient(blob_container_url)

            const blob_items = []
            let blobs = blob_container_client.listBlobsFlat()
            let file_index = 0
            for await (const blob of blobs) {
                const item = { 
                    id: ++file_index,
                    name: blob.name,
                    edit_date: blob.properties.lastModified
                }
                blob_items.push(item)
            }
            blob_items.sort((a, b) => b.edit_date - a.edit_date)
            setItems(blob_items)
        }
        getBlobs(urlBase)
    }, [urlBase])

    const grid_columns = [
        { 
            field: 'name', 
            headerName: 'PortML Studio Packages', 
            flex: 0.7,
            renderCell: (row_prop) => (
                <Link href={`${urlBase}${row_prop.value}`}>{row_prop.value}</Link>
            )
        },
        { 
            field: 'edit_date', 
            headerName: 'Last Modified Date', 
            flex: 0.3,
            minWidth:150,
            valueGetter: (value) => `${value.toISOString().replace('T', ' ').substring(0, 19)}`
        }
    ]


   return (
    <Box sx={{width: '100%'}}>
        <DataGrid 
            rows={items}
            columns={grid_columns}
            initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                  },
                },
              }}
            pageSizeOptions={[pageSize]}
            disableRowSelectionOnClick
        />
    </Box>
   )
}

export default BlobList