import { BrowserRouter, Route, Routes } from 'react-router-dom'
import BlobPage from './pages/BlobPage'
import './App.css';


function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BlobPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
